import React from "react";
import { RichText } from "@sitecore-jss/sitecore-jss-react";
import ImageLink from "../../../core/Components/ImageLink";
import Link from "../../../core/Components/Link";
import { DefaultProps, PropTypes } from "../../../core/Utils/SitecoreFields";
import Nav from "./Components/Nav";
import Socials from "./Components/Socials";
import { withHeaderContext } from "../../../HeaderContext";

const Footer = ({ fields, headerContext }) => {
  return (
    <footer
      className={`global-footer${headerContext?.showMobileMenu ? " is-hidden" : ""}`}
    >
      <div className="l-padding">
        <nav>
          <ul className="nav-footer has-5-items">
            <li className="brandImg">
              <ImageLink imageField={fields.logo} linkField={fields.logoLink} />
            </li>
            <Nav links={fields.columnOneLinks} columnClass="columns2" />
            <Nav links={fields.columnTwoLinks} columnClass="columns2" />
            <Nav links={fields.columnThreeLinks} columnClass="column" />
            <Socials fields={fields} />
          </ul>
        </nav>
      </div>
      <div className="subfooter-container">
        {fields.bottomLinks.map(({ fields }) => (
          <div
            className="subfooter-link"
            key={fields.link.id || fields.link.value.id}
          >
            <Link field={fields.link} />
          </div>
        ))}

        <div className="subfooter-copyright">
          <RichText field={fields.copyright} />
        </div>
      </div>
    </footer>
  );
};

Footer.defaultProps = DefaultProps.SitecoreItem.inject({
  logo: DefaultProps.Image,
  logoLink: DefaultProps.Link,
  columnOneLinks: DefaultProps.Multilist,
  columnTwoLinks: DefaultProps.Multilist,
  columnThreeLinks: DefaultProps.Multilist,
  socials: DefaultProps.Multilist,
  copyright: DefaultProps.RichText,
});

Footer.propTypes = PropTypes.SitecoreItem.inject({
  logo: PropTypes.Image,
  logoLink: PropTypes.Link,
  columnOneLinks: PropTypes.Multilist.inject({
    link: PropTypes.Link,
  }),
  columnTwoLinks: PropTypes.Multilist.inject({
    link: PropTypes.Link,
  }),
  columnThreeLinks: PropTypes.Multilist.inject({
    link: PropTypes.Link,
  }),
  socials: PropTypes.Multilist.inject({
    ...PropTypes.ImageLink,
    title: PropTypes.SingleLineText,
  }),
  copyright: PropTypes.RichText,
});

export default withHeaderContext(Footer);
