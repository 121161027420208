import React from 'react';
import ImageLink from '../../../../core/Components/ImageLink';

const Socials = ({ fields }) => {
  return (
    <li className="nav-footer-social nav-footer-item link-icons-disabled">
      <ul
        className={
          fields.socials.length > 4
            ? 'mtf-items socials-items'
            : 'socials-items'
        }
      >
        {fields.socials.map(({ fields, id }) => (
          <li key={id}>
            <ImageLink
              imageField={fields.image}
              className="icon"
              linkField={fields.link}
            />
          </li>
        ))}
      </ul>
    </li>
  );
};

export default Socials;
